import { Link } from "gatsby"
import React, { Component } from "react"
import Button from "./Button"
import * as styles from "./Hero.module.css"

export default class Hero extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <section className={`${styles.siteHero} ${styles.siteHeroHome}`}>
        <div className={`${styles.container} grid`}>
          <h1
            className={`${styles.siteHero__title} ${
              this.props.color === "black"
                ? styles.siteHero__titleBlack
                : styles.siteHero__titleWhite
            } col-12 col-l10`}
          >
            {this.props.title}{" "}
            <span className={styles.siteHero__gradientTitle}>
              {this.props.titleGradient}
            </span>
          </h1>
          <p
            className={`${styles.siteHero__subtitle} ${
              this.props.color === "black"
                ? styles.siteHero__subtitleBlack
                : styles.siteHero__subtitleWhite
            } col-12 col-m12 col-l12`}
          >
            {this.props.text}
          </p>
          <div
            className={`${
              this.props.buttonTo ? styles.siteHero__button : ""
            } col-12 col-m4 col-l4`}
          >
            {this.props.buttonTo ? (
              <Button to={this.props.buttonTo} name={this.props.buttonName} />
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    )
  }
}
