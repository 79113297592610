// extracted by mini-css-extract-plugin
export const menu = "Menu-module--menu--XvS32";
export const siteMenu = "Menu-module--site-menu--e6Gzr";
export const hidden = "Menu-module--hidden--p-5kb";
export const siteHeader = "Menu-module--site-header--3C5M9";
export const siteHeader__main = "Menu-module--site-header__main--3ii63";
export const siteHeader__branding = "Menu-module--site-header__branding--1vK6m";
export const siteHeader__brandingLogo = "Menu-module--site-header__branding-logo--2Y0St";
export const siteHeader__brandingLogoMobile = "Menu-module--site-header__branding-logo-mobile--a_zxK";
export const siteMenu__items = "Menu-module--site-menu__items--351Vr";
export const siteMenu__item = "Menu-module--site-menu__item--2cWYW";
export const siteMenu__itemMobile = "Menu-module--site-menu__item-mobile--1J0Qz";
export const siteMenu__link = "Menu-module--site-menu__link--38R8d";
export const siteMenu__linkWhite = "Menu-module--site-menu__link--white--1boU1";
export const siteMenu__linkBlack = "Menu-module--site-menu__link--black--JZefw";
export const siteHeader__aside = "Menu-module--site-header__aside--KPRyB";
export const siteHeader__asideButton = "Menu-module--site-header__aside-button--1EeRe";
export const siteMenu__bottom = "Menu-module--site-menu__bottom--2xXuI";
export const siteMenu__shape = "Menu-module--site-menu__shape--25xqH";
export const siteMenu__bottomList = "Menu-module--site-menu__bottom-list--1WIEk";
export const siteMenu__bottomContact = "Menu-module--site-menu__bottom-contact--1ni2D";
export const active = "Menu-module--active--29rGZ";
export const siteHeader__brandingLogoBlack = "Menu-module--site-header__branding-logo--black--3IiLR";
export const siteHeader__brandingLogoMobileBlack = "Menu-module--site-header__branding-logo-mobile--black--cXR42";