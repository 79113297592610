// import React from "react"
// import * as styles from "./HamburgerButton.module.css"

// export default function HamburgerButton() {

//   return (

//   )
// }

import React, { Component } from "react"
import * as styles from "./HamburgerButton.module.css"

export default class HamburgerButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggle: styles.inactive,
      value: false,
      hamburgerColor: this.props.hamburgerColor,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    this.setState(state => ({
      toggle: !state.toggle ? styles.active : "",
      value: state.value === false ? true : false,
    }))
    this.props.onChange(this.state.value)

    /**
     * Check for color scheme, set in layout component.
     */
    if (this.props.colorScheme === "black") {
      if (this.state.hamburgerColor === "black") {
        this.setState(state => ({
          hamburgerColor: "white",
        }))
      } else if (this.state.hamburgerColor === "white") {
        this.setState(state => ({
          hamburgerColor: "black",
        }))
      }
    }
  }

  render() {
    return (
      <button
        className={`${styles.hamburgerButton} ${styles.hamburgerButtonBlack} ${this.state.toggle}`}
        onClick={this.handleClick}
      >
        <div
          className={`${styles.hamburgerButton__background} ${
            styles.hamburgerButton__backgroundWhite
          } ${
            this.state.hamburgerColor === "black"
              ? styles.active
              : styles.hidden
          }`}
        ></div>
        <div
          className={`${styles.hamburgerButton__background} ${
            styles.hamburgerButton__backgroundBlack
          } ${
            this.state.hamburgerColor === "black"
              ? styles.hidden
              : styles.active
          }`}
        ></div>
        <div
          className={`${styles.hamburgerLine} ${
            this.state.hamburgerColor === "black"
              ? styles.hamburgerLineBlack
              : styles.hamburgerLineWhite
          } ${styles.hamburgerLineTop}`}
        ></div>
        <div
          className={`${styles.hamburgerLine} ${
            this.state.hamburgerColor === "black"
              ? styles.hamburgerLineBlack
              : styles.hamburgerLineWhite
          } ${styles.hamburgerLineMiddle}`}
        ></div>
        <div
          className={`${styles.hamburgerLine} ${
            this.state.hamburgerColor === "black"
              ? styles.hamburgerLineBlack
              : styles.hamburgerLineWhite
          } ${styles.hamburgerLineBottom}`}
        ></div>
      </button>
    )
  }
}
