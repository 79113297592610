import React, { Component } from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"

export default class services extends Component {
  render() {
    return (
      <Layout>
        <Hero
          title="This is what we do"
          text={`Our playing field is where technology, user needs and business goals meet. The intersection is the sweet spot we call "user experience"`}
        />
      </Layout>
    )
  }
}
