import React, { Component } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import * as styles from "./Menu.module.css"
import HamburgerButton from "./HamburgerButton"
import subtract from "../../static/subtract-mobile.svg"
import Button from "./Button"

export default class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = { menuState: false, logoColor: this.props.logoColor }
  }

  handleChange = value => {
    this.setState({
      menuState: value === false ? styles.active : "",
      logoColor: this.props.logoColor,
    })

    if (this.props.colorScheme === "black") {
      if (this.state.logoColor === "black") {
        this.setState({
          logoColor: "white",
        })
      } else {
        this.setState({
          logoColor: "black",
        })
      }
    }
  }

  render() {
    return (
      <header className={`${styles.siteHeader} ${this.state.menuState}`}>
        <div className={`${styles.menu} container`}>
          <div className={styles.siteHeader__main}>
            <div className={styles.siteHeader__branding}>
              <Link to="/" aria-current="page">
                <svg
                  width="135"
                  height="40"
                  viewBox="0 0 135 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${styles.siteHeader__brandingLogo} ${styles.siteHeader__brandingLogoWhite}`}
                >
                  <path
                    d="M56.2872 14.6023L52.8551 14.7895C52.8551 13.4166 51.8566 12.4806 50.2965 12.4806C48.7365 12.4806 47.8629 13.2918 47.8629 14.4774C47.8629 15.7255 48.7989 16.0999 51.0454 16.5991C54.4151 17.4728 56.5992 18.4712 56.5992 21.4041C56.5992 24.8987 53.8535 26.3963 50.5462 26.3963C46.7396 26.3963 43.9939 24.5242 43.9939 21.1545L47.426 20.9673C47.4884 22.465 48.7989 23.4634 50.5462 23.4634C52.0438 23.4634 53.1046 22.9018 53.1046 21.5289C53.1046 20.2809 51.919 19.8441 49.7349 19.2824C46.802 18.5336 44.3059 17.7224 44.3059 14.5399C44.3059 11.8565 46.2404 9.54765 50.1093 9.54765C53.7911 9.61006 56.2248 11.4821 56.2872 14.6023Z"
                    fill="white"
                  ></path>
                  <path
                    d="M63.0892 21.8409C63.0892 23.089 63.9004 23.5258 64.7741 23.5258C65.2109 23.5258 65.5229 23.4634 65.7725 23.401V26.2715C65.3357 26.3339 64.8989 26.3963 64.0252 26.3963C61.5915 26.3963 59.9067 25.2107 59.9067 22.5898V17.5976H57.8474V14.8519H59.9067V11.1077H63.0892V14.8519H65.7101V17.5976H63.0268L63.0892 21.8409Z"
                    fill="white"
                  ></path>
                  <path
                    d="M74.1967 17.8471C72.387 17.8471 71.2013 18.908 71.2013 20.468V26.2091H68.0188V14.8518H71.1389V16.1623C71.6381 15.4134 72.5118 14.7894 74.259 14.7894V17.8471H74.1967Z"
                    fill="white"
                  ></path>
                  <path
                    d="M84.4931 16.0375V14.8518H87.4884V26.2091H84.4931V25.0234C83.9315 25.7723 82.7458 26.3963 81.1234 26.3963C77.9408 26.3963 75.6943 24.025 75.6943 20.5305C75.6943 16.9735 77.9408 14.6646 81.1234 14.6646C82.6834 14.6646 83.8691 15.2887 84.4931 16.0375ZM78.8769 20.5305C78.8769 22.5273 80.0001 23.6506 81.6226 23.6506C83.1826 23.6506 84.3059 22.5897 84.3059 20.7801V20.2809C84.3059 18.4712 83.1826 17.4103 81.6226 17.4103C80.0001 17.4103 78.8769 18.5336 78.8769 20.5305Z"
                    fill="white"
                  ></path>
                  <path
                    d="M97.5352 14.8518H101.404L97.1608 19.7192L101.716 26.2091H97.8473L94.9143 22.0905L93.9159 23.2137V26.2091H90.7334V9.17318H93.9159V18.908L97.5352 14.8518Z"
                    fill="white"
                  ></path>
                  <path
                    d="M111.576 23.6505V26.209H102.091L106.771 17.4727H102.403V14.9142H111.513L106.833 23.6505H111.576Z"
                    fill="white"
                  ></path>
                  <path
                    d="M121.935 16.0375V14.8518H124.93V26.2091H121.935V25.0234C121.373 25.7723 120.187 26.3963 118.565 26.3963C115.382 26.3963 113.136 24.025 113.136 20.5305C113.136 16.9735 115.382 14.6646 118.565 14.6646C120.187 14.6646 121.373 15.2887 121.935 16.0375ZM116.381 20.5305C116.381 22.5273 117.504 23.6506 119.126 23.6506C120.686 23.6506 121.81 22.5897 121.81 20.7801V20.2809C121.81 18.4712 120.686 17.4103 119.126 17.4103C117.504 17.4103 116.381 18.5336 116.381 20.5305Z"
                    fill="white"
                  ></path>
                  <path
                    d="M132.044 21.8409C132.044 23.089 132.855 23.5258 133.729 23.5258C134.166 23.5258 134.478 23.4634 134.727 23.401V26.2715C134.291 26.3339 133.854 26.3963 132.98 26.3963C130.546 26.3963 128.862 25.2107 128.862 22.5898V17.5976H126.802V14.8519H128.862V11.1077H132.044V14.8519H134.665V17.5976H131.982L132.044 21.8409Z"
                    fill="white"
                  ></path>
                  <path
                    d="M8.73635 12.9174V17.66L17.4727 21.1545L30.5772 15.9127V30.0157L17.4727 35.3199L0 28.2684V32.9486L17.4727 40.0001L34.9454 32.9486V9.42284L17.4727 16.4743L8.73635 12.9174Z"
                    fill="white"
                  ></path>
                  <path
                    d="M17.4727 7.05148L0 0V23.5257L17.4727 30.5772L26.209 27.0827V22.3401L17.4727 25.897L4.36818 20.5928V6.48986L17.4727 11.7317L34.9454 4.68019V0L17.4727 7.05148Z"
                    fill="white"
                  ></path>
                </svg>
                <svg
                  width="35"
                  height="40"
                  viewBox="0 0 35 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${styles.siteHeader__brandingLogoMobile} ${
                    this.state.logoColor === "black"
                      ? styles.siteHeader__brandingLogoMobileBlack
                      : styles.siteHeader__brandingLogoMobileWhite
                  }`}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.71893 12.9595V17.6324L17.4379 21.1838L30.5163 15.8878V29.9688L17.4379 35.2648L0 28.2243V32.9595L17.4379 40L34.8757 32.9595V9.40808L17.4379 16.4486L8.71893 12.9595Z"
                    fill="white"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.4379 7.0405L0 -1.48547e-08V23.5514L17.4379 30.5919L26.1568 27.0405V22.3676L17.4379 25.8567L4.35947 20.5608V6.47975L17.4379 11.7757L34.8757 4.7352V-1.48547e-08L17.4379 7.0405Z"
                    fill="white"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <div
            className={`${styles.siteMenu} ${styles.hidden} ${this.state.menuState}`}
          >
            <ul className={styles.siteMenu__items}>
              <li className={styles.siteMenu__item}>
                <Link
                  to="/work"
                  className={`${styles.siteMenu__link} ${styles.siteMenu__linkWhite}`}
                >
                  Work
                </Link>
              </li>
              <li className={styles.siteMenu__item}>
                <Link
                  to="/services"
                  className={`${styles.siteMenu__link} ${styles.siteMenu__linkWhite}`}
                >
                  Services
                </Link>
              </li>
              <li className={styles.siteMenu__item}>
                <Link
                  to="/about"
                  className={`${styles.siteMenu__link} ${styles.siteMenu__linkWhite}`}
                >
                  About
                </Link>
              </li>
              <li
                className={`${styles.siteMenu__item} ${styles.siteMenu__itemMobile}`}
              >
                <Link
                  to="/contact"
                  className={`${styles.siteMenu__link} ${styles.siteMenu__linkWhite}`}
                >
                  Get In Touch
                </Link>
              </li>
              <div className={styles.siteMenu__bottom}>
                <img
                  src={subtract}
                  alt="subtract"
                  className={styles.siteMenu__shape}
                />
                <ul className={styles.siteMenu__bottomList}>
                  <li className={styles.siteMenu__bottomContact}>
                    <a href="tel:+31619610233">+31 (0)10 30 70 484</a>
                  </li>
                  <li className={styles.siteMenu__bottomContact}>
                    <a href="mailto:hello@lostfield.nl">hello@lostfield.nl</a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          <div className={styles.siteHeader__aside}>
            <div className={styles.siteHeader__asideButton}>
              <Button style="blue" name="Get in touch" to="/contact" />
            </div>
            <HamburgerButton
              onChange={this.handleChange}
              hamburgerColor={this.props.hamburgerColor}
              colorScheme={this.props.colorScheme}
            />
          </div>
        </div>
      </header>
    )
  }
}
