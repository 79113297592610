import React, { Component } from "react"
import Menu from "./Menu"
import * as styles from "../styles/layout.module.css"

export default class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      enter: "page-container",
    }

    Layout.defaultProps = {
      colorScheme: "white",
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        enter: "page-container animate-enter-done",
      })
    }, 10)
  }

  render() {
    return (
      <div className="pages-container">
        <Menu
          logoColor={this.props.colorScheme}
          hamburgerColor={this.props.colorScheme}
          colorScheme={this.props.colorScheme}
        />
        <div className={this.state.enter}>{this.props.children}</div>
      </div>
    )
  }
}
