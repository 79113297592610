import { Link } from "gatsby"
import React, { Component } from "react"
import * as styles from "./Button.module.css"

export default class Button extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.buttonStyle = this.buttonStyle.bind(this)

    Button.defaultProps = {
      style: "grey",
    }
  }

  buttonStyle() {
    let buttonStyle = null

    if (this.props.style) {
      if (this.props.style === "white") {
        return (buttonStyle = styles.buttonWhite)
      } else if (this.props.style === "grey") {
        return (buttonStyle = styles.buttonGrey)
      } else if (this.props.style === "blue") {
        return (buttonStyle = styles.buttonBlue)
      }
    } else {
      return (buttonStyle = styles.buttonGrey)
    }
  }

  render() {
    return (
      <Link
        to={this.props.to}
        className={`${styles.button} ${this.buttonStyle()}`}
      >
        <div className={`${styles.button__background}`}></div>
        <p className={`${styles.button__content}`}>{this.props.name}</p>
      </Link>
    )
  }
}
